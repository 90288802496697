import _ from 'lodash'
import day from '@/utils/day'

import getMenuCategory from '@/utils/get-menu-category'

export const namespaced = true

export const state = () => ({
  rekomendasiMenu: [],
  rencanaMakan: [],
  profileGizi: null,
  asupanGizi: null,
  kondisiTubuh: null,
  saran: null,
  latestProgram: null,
})

export const getters = {
  rencanaMakan: (state) => ({
    breakfast: state.rencanaMakan.find((it) => it.category === 'breakfast'),
    morning_snack: state.rencanaMakan.find((it) => it.category === 'morning_snack'),
    lunch: state.rencanaMakan.find((it) => it.category === 'lunch'),
    afternoon_snack: state.rencanaMakan.find((it) => it.category === 'afternoon_snack'),
    dinner: state.rencanaMakan.find((it) => it.category === 'dinner'),
    night_snack: state.rencanaMakan.find((it) => it.category === 'night_snack'),
  }),
  profileGizi: (state) => state.profileGizi,
  asupanGizi: (state) => state.asupanGizi,
  kondisiTubuh: (state) => state.kondisiTubuh,
  saran: (state) => state.saran,
}

export const mutations = {
  setRekomendasiMenu(state, rekomendasi) {
    state.rekomendasiMenu = rekomendasi
  },
  setRencanaMakan(state, rencana) {
    state.rencanaMakan = rencana
  },
  setProfileGizi(state, profile) {
    state.profileGizi = profile
  },
  setAsupanGizi(state, asupan) {
    state.asupanGizi = asupan
  },
  setKondisiTubuh(state, kondisi) {
    state.kondisiTubuh = kondisi
  },
  setSaran(state, saran) {
    state.saran = saran
  },
  setLatestProgram(state, program) {
    state.latestProgram = program
  },
}

export const actions = {
  async getActiveDays(ctx, { programId } = {}) {
    if (programId == null) return Promise.reject('programId are null')

    let axios = ctx.rootGetters.axios
    return axios.get(`/v1/clients/meals-recommendation/${programId}/active-day`).then((r) => r.data.data)
  },
  async list(ctx, { clientId, programId }) {
    let axios = ctx.rootGetters.axios

    await ctx.dispatch('clients/getClientById', clientId, { root: true })

    return axios
      .get(`/v1/nutritionist/meal-plans/${programId}`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setRencanaMakan', it.data)
        return it.data
      })
  },
  async listRekomendasiMenu(ctx, { clientId, programId, day }) {
    let axios = ctx.rootGetters.axios
    await ctx.dispatch('clients/getClientById', clientId, { root: true })

    return axios
      .get(`/v1/nutritionist/meals-recommendation/${programId}/${day}`)
      .then((it) => it.data)
      .then((it) => {
        ctx.commit('setRekomendasiMenu', it.data)
        return it.data
      })
  },
  async getFormattedRekomendasiMenu(ctx, clientId) {
    await ctx.dispatch('list', clientId)
    await ctx.dispatch('listRekomendasiMenu', clientId)
    let rencanaMakan = ctx.getters.rencanaMakan
    let menus = ctx.state.rekomendasiMenu

    let result = {}
    for (let [key, value] of Object.entries(rencanaMakan)) {
      if (value == null) continue
      if (result[key] == null) result[key] = { ...value }

      result[key].id = value.id

      let category = getMenuCategory(value.category)
      result[key].title = `${category} - ${value.totalCalories}kkal`

      let timeStart = day(value.timeStart, 'HH:mm:ss').format('HH A')
      let timeEnd = day(value.timeEnd, 'HH:mm:ss').format('HH A')
      result[key].time = `${timeStart} - ${timeEnd}`

      result[key].meals = menus
        .flatMap((it) => {
          return it.menuData.map((item) => {
            return {
              ...item,
              day: it.day,
              rekomendasiMenuId: it.id,
            }
          })
        })
        // .filter((it) => it.mealPlansData.category === key)
        .flatMap((it) => {
          return it.meals.map((item) => {
            return {
              ...item,
              day: it.day,
              rekomendasiMenuId__: it.id,
              mealsRecommendationId: it.mealsRecommendationId,
            }
          })
        })
        .map((it) => ({
          ...it,
          text: `${it.dose} ${it.doseUnit} ${it.foodIngredient}`,
        }))
    }
    return Object.values(result)
  },
  getProfileGizi(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/nutrition-profile/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setProfileGizi', it)
        return it
      })
  },
  createProfileGizi(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/nutritionist/nutrition-profile', data)
      .then((r) => r.data.data)
      .then((r) => {
        return r
      })
  },
  updateProfileGizi(ctx, { profileGizi }) {
    if (profileGizi.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put(`/v1/nutritionist/nutrition-profile/${profileGizi.id}`, profileGizi)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
  },
  getAsupanGizi(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/nutrition-intake/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
  },
  postAsupanGizi(ctx, { asupanGizi, clientId }) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/nutritionist/nutrition-intake', { ...asupanGizi, clientId })
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
  },
  updateAsupanGizi(ctx, { asupanGizi }) {
    if (asupanGizi.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put(`/v1/nutritionist/nutrition-intake/${asupanGizi.id}`, asupanGizi)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
  },
  getKondisiTubuh(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/body-proportion-lifestyle/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setKondisiTubuh', it)
      })
  },
  createKondisiTubuh(ctx, data) {
    let axios = ctx.rootGetters.axios
    return axios
      .post('/v1/nutritionist/body-proportion-lifestyle', data)
      .then((r) => r.data.data)
      .then((r) => {
        return r
      })
  },
  updateKondisiTubuh(ctx, { kondisiTubuh }) {
    if (kondisiTubuh.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put(`/v1/nutritionist/body-proportion-lifestyle/${kondisiTubuh.id}`, kondisiTubuh)
      .then((it) => it.data.data)
  },
  getSaran(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/nutrition-lifestyle-advice/${clientId}`)
      .then((it) => it.data.data)
      .then((it) => {
        ctx.commit('setSaran', it)
      })
      .catch(() => {})
  },
  updateSaran(ctx, { saran, programId }) {
    if (saran.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .put(`/v1/nutritionist/nutrition-lifestyle-advice/${saran.id}`, {
        ...saran,
        programId,
      })
      .then((it) => it.data.data)
  },
  createSaran(ctx, { saran, clientId, programId }) {
    if (saran.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios

    return axios
      .post('/v1/nutritionist/nutrition-lifestyle-advice', {
        ...saran,
        clientId,
        programId,
      })
      .then((it) => it.data.data)
  },
  createMealPlan(ctx, { clientId, mealPlans }) {
    if (mealPlans.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios
    mealPlans = cleanMealPlans(mealPlans)

    return axios.post(`/v1/nutritionist/meal-plans/${clientId}`, mealPlans).then((it) => it.data.data)
  },
  updateMealPlan(ctx, { mealPlans }) {
    if (mealPlans.length === 0) return Promise.resolve()

    let axios = ctx.rootGetters.axios
    mealPlans = cleanMealPlans(mealPlans)

    return axios
      .put('/v1/nutritionist/meal-plans', mealPlans)
      .then((it) => it.data.data)
      .then((it) => {
        return it
      })
  },
  getLatestProgramForNutri(ctx, clientId) {
    let axios = ctx.rootGetters.axios
    return axios
      .get(`/v1/nutritionist/clients/${clientId}/latest-program`)
      .then((r) => r.data.data)
      .then((it) => {
        ctx.commit('setLatestProgram', it)
        return it
      })
  },
  getLatestProgramForClient(ctx) {
    let axios = ctx.rootGetters.axios
    return axios
      .get('/v1/clients/programs')
      .then((r) => r.data.data)
      .then((it) => it.find((p) => p.isLatest))
      .then((it) => {
        ctx.commit('setLatestProgram', it)
        return it
      })
  },
}

function cleanMealPlans(mealPlans) {
  return mealPlans.map((item) => {
    item.meals = item.meals.map((meal) => {
      return _.pick(meal, [
        'id',
        'dose',
        'doseUnit',
        'ingredientName',
        'foodIngredient',
        'foodGroup',
        'mealGlossaryId',
        'mealPlansId',
        'status',
      ])
    })
    return item
  })
}
